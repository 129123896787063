

import React from 'react';
import {useTranslation} from "react-i18next";

function TrackingProjectPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'min-vh-100 pt-5'}>

        <div className="container d-flex justify-content-between text-dark text-start pt-5 mt-5">

            <div className="d-flex flex-column justify-content-between">
                <div className={'form'}>
                    <h1>Tracking project</h1>
                    <br/>
                    <input type="text" placeholder={'Access code'} className={'p-3 rounded-0 border-0 border-bottom'}/>
                    <button className={'btn btn-dark rounded-0 p-3'}>
                        Check
                    </button>
                </div>


                <a href="/" className={'nav-link'}>
                    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M5 12h14M5 12l4-4m-4 4 4 4"/>
                    </svg>
                    Back
                </a>
            </div>

            <div className="border-0 col-md-6 col-12">
                <h2>What you get?</h2>
                <br/>
                <p>
                    1. Project status
                </p>
                <p>
                    2. History project
                </p>
                <p>
                    3. Detail information
                </p>
                <p>
                    4. Leave comments
                </p>
                <p>
                    5. Add task
                </p>
            </div>
        </div>
    </div>);
}

export default TrackingProjectPage;
