

import React, {useState} from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';

// import '@splidejs/react-splide/css';
// import {useTranslation} from "react-i18next";
// import {MotionAnimate} from "react-motion-animate";
import Zoom from "react-medium-image-zoom";

function SliderContent () {

    // const { t, i18n } = useTranslation()
    //
    // const _lang = i18n.language;

    const _data = [
        {
            id: 1,
            label: "title",
            // title: {
            //     ua: "Земельні ділянки",
            //     en: "Land plots",
            // },
            // description: {
            //     ua: "Ми пропонуємо земельну ділянку під будівництво власного будинку або таунхауса поблизу м.Житомир, із вже наявними комунікаціями з інфраструктурою та",
            //     en: "We offer a plot of land for the construction of your own house or townhouse near the city of Zhytomyr, with already existing communications with infrastructure and"
            // },
            // slug: "land",
            url: "https://images.unsplash.com/photo-1507679799987-c73779587ccf?q=80&w=3271&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            id: 2,
            label: "title",
            url: "https://images.unsplash.com/photo-1453928582365-b6ad33cbcf64?q=80&w=3273&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 3,
            label: "title",
            url: "https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?q=80&w=3271&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            id: 4,
            label: "title",
            url: "https://plus.unsplash.com/premium_photo-1661575479396-96170d1b0187"
        },
    ];

    return <>
        <Splide
            options={ {
                rewind: true,
                rewindByDrag: true,
                gap   : '1rem',
                // width: 500,
                perPage: 3,
                // height : '50vh',
                // rewind : true,
                // gap    : '1rem',
                autoplay: true,
                drag: 'free',
            } }
            aria-label="Town house Images"
        >
            {
                _data.map((img, i) =>   <SplideSlide key={i}>
                    <Zoom> <img src={img.url} alt={img.label} className={'img-fluid rounded-5'}/></Zoom>
                </SplideSlide>)
            }
        </Splide>
    </>
}

export default SliderContent;
