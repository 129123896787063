

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../../components/section";
import CardPortfolio from "../card_portfolio";

function SectionPortfolio(props) {

    const { t, i18n } = useTranslation()

    return (<div>

        <Section title={''} name={''} child={<div className={'rounded-5'}>

            <div className="container-fluid">

                <div className="">
                    <div id={'whtat-todo__title-position'} className="pb-5">
                        <small className="text-secondary">
                            my latest work project
                        </small>

                        <h2 className={'display-1'}>
                            Portfolio
                        </h2>

                    </div>
                </div>

                <div className="d-flex flex-wrap align-items-center">

                    <CardPortfolio />

                    <CardPortfolio />

                    <CardPortfolio />

                    <CardPortfolio />

                </div>

                {/*<div className="link-view-more text-center mt-5">*/}
                {/*    <a href="/portfolio" className={'text-decoration-none lead text-secondary'}>*/}
                {/*        <small>*/}
                {/*            view more*/}
                {/*        </small>*/}
                {/*    </a>*/}
                {/*</div>*/}

            </div>

        </div>} link={'/'} linkName={''}/>

        <div className="text-end p-5">
            <a href="/portfolio" className={'text-decoration-none text-dark display-4'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                     className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                </svg> <b>all projects</b>
            </a>
        </div>

    </div>);
}

export default SectionPortfolio;
