

import React from 'react';
import {useTranslation} from "react-i18next";
import AccordionItemCustom from "../components/accordion";
import DataPivacyPolicy from "../data/privacy-policy";

function ContactsPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'min-vh-100'}>


        <div className="container text-dark text-start pt-5 mt-5">
            <h1>{t('nav.contacts')}</h1>

            <div className="row">
                <div className="col-md-8">

                   <div className="mt-5">
                       <h3>contacts</h3>
                       <p>
                         email:  <a href="mailto:yarik@lukyanchuk.com" className={'text-decoration-none text-secondary lead'}>yarik@lukyanchuk.com</a>
                       </p>
                       <p>
                           tel.: <a href="tel.: +380 (98) 075 13 57"  className={'text-decoration-none text-secondary lead'}>+380 (98) 075 13 57</a>
                       </p>
                       <p>
                           tel.: <a href="tel.: +380 (63) 075 13 57"  className={'text-decoration-none text-secondary lead'}>+380 (63) 075 13 57</a>
                       </p>
                       <p>
                           adress.: <a href="https://www.google.com/maps"  className={'text-decoration-none text-secondary lead'}>10001, Ukraine</a>
                       </p>
                   </div>

                    <div className="mt-5">
                        <h3>social media</h3>
                        <p>
                            <a href="" className={'text-decoration-none text-secondary lead'}>linkedin</a>
                        </p>
                        <p>
                            <a href="" className={'text-decoration-none text-secondary lead'}>instagram</a>
                        </p>
                        <p>
                            <a href="" className={'text-decoration-none text-secondary lead'}>X (twitter)</a>
                        </p>
                        <p>
                            <a href="" className={'text-decoration-none text-secondary lead'}>facebook</a>
                        </p>
                        <p>
                            <a href="" className={'text-decoration-none text-secondary lead'}>youtube</a>
                        </p>
                        <p>
                            <a href="" className={'text-decoration-none text-secondary lead'}>git</a>
                        </p>
                    </div>

                    <div className="mt-5">
                        <h3>referal links</h3>
                        <p>
                            <a href="" className={'text-decoration-none text-secondary lead'}>binance</a>
                        </p>
                        <p>
                            <a href="" className={'text-decoration-none text-secondary lead'}>bybit</a>
                        </p>
                        <p>
                            <a href="" className={'text-decoration-none text-secondary lead'}>hostpro</a>
                        </p>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className={'sticky-top'}>
                        <h3 className={'mt-5'}>payments</h3>
                        <img src="https://benya.pro/wp-content/uploads/2024/04/image.png" alt="payments" className={'rounded-2 shadow w-100'}/>

                        <h3 className={'mt-5'}>
                            USDT
                        </h3>
                        <small className="text-secondary">
                            TRC20
                        </small>
                        <p>
                            TVUAA7f5kjnVJtauKRb6GxW6DaWqkW8fbY
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>);
}

export default ContactsPage;
