


function CardPortfolio ({props}){


    return <div className={'col-md-6 col-12 p-md-2 p-1'}>
        <div className="border rounded-5 p-md-5 p-4 hover-div">

            <div className="row">
                <div className="col-md-8  col-12 portfolio-card-image p-0 d-none d-md-block">
                    <img src="https://external-preview.redd.it/sources-ios-18-lets-users-customize-layout-of-home-screen-v0-x1IyxFXHl7P62erbI7t1zMNt2CpnHJmo5XeFzmaQuxU.jpg?auto=webp&s=104a047cf44fb57eaedf207227b837b46bf9d44d" alt="portfolio_image_cover_#" className={'img-fluid'}/>
                </div>
                <div className="col-md-4 col-12 p-0">
                    <img src="https://external-preview.redd.it/sources-ios-18-lets-users-customize-layout-of-home-screen-v0-x1IyxFXHl7P62erbI7t1zMNt2CpnHJmo5XeFzmaQuxU.jpg?auto=webp&s=104a047cf44fb57eaedf207227b837b46bf9d44d" alt="portfolio_image_cover_#" className={'img-fluid rounded- rounded-top-4 d-md-none d-block'}/>
                    <div className="text-center pt-3 d-md-none d-block">

                        <a href="/portfolio/service_name/slug-project" className={'align-items-center d-flex justify-content-around text-decoration-none'}>
                            <h2 className={'text-dark'}>Project title</h2>
                            <br/>
                            <small className="text-secondary">
                                category/service
                            </small>
                        </a>
                    </div>
                    <div className="portfolio-card-info d-none d-md-block">
                        <h2 className={'text-dark'}>Project title</h2>
                        <br/>
                        <small className="text-white">
                            category/service
                        </small>
                    </div>
                    <div className="link-know-more p-3 pt-0 pb-0 d-none d-md-block">
                        <a href="/portfolio/service_name/slug-project" className={'align-items-center d-flex text-decoration-none'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                 className="bi bi-arrow-right text-dark" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                            </svg> <small className="m-2 text-dark lead">Know more</small>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default CardPortfolio;
