

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../../components/section";
import AccordionItemCustom from "../accordion";

function SectionQandA(props) {

    const { t, i18n } = useTranslation()

    return (<div>

        <div className="text-center">
            <div id={'whtat-todo__title-position'} className="p-5">
                <small className="text-secondary">
                    Futures
                </small>

                <h2 className={'display-1'}>
                    Information
                </h2>

            </div>
        </div>

        <Section title={''} name={''} child={<div className={'rounded-5 bg-white text-white'}>

            <div className="container-fluid">
                <div className="d-flex flex-wrap justify-content-between align-items-start">

                    <div className="col-md-6 col-12 p-2">
                        <div className="q-a" style={{marginRight: '20px'}}>
                            <h2 className={'text-secondary lead'}>
                                Popular questions
                            </h2>
                            <br/>
                            <AccordionItemCustom data={[
                                {
                                    id: 1,
                                    title: "How long does the project take?",
                                    content: <div>
                                        <b>Все залежить від обсягу роботи, які потрібно виконати для реалізації проекту.</b>
                                    </div>
                                },
                                {
                                    id: 2,
                                    title: "How make orders?",
                                    content: <div>
                                        <p>
                                            Ви можете скористатись одним із способів зв'язку:
                                        </p>
                                        <ul>
                                            <li>
                                                Скористайтесь формой для співпраці
                                            </li>
                                            <li>
                                                Зателефонуйте за номером 098 075 13 57
                                            </li>
                                            <li>
                                                Напишіть на email: yarik@lukyanchuk.com
                                            </li>
                                            <li>
                                                Через месенджер Whatsapp / Telegram / Viber
                                            </li>
                                            <li>
                                                Соціальні мережі
                                            </li>
                                        </ul>
                                    </div>
                                }
                            ]} />
                        </div>
                    </div>

                    <div className="col-md-6 col-12 p-2">
                        <div className="more-information">

                            <h2 className={'text-secondary lead'}>
                                Future release
                            </h2>

                            <p className={'text-dark display-3'}>
                                Android APK
                            </p>
                            <h2 className={'text-secondary lead mt-5'}>
                                for clients
                            </h2>
                            <a href="/project/tracking" className={'display- text-decoration-none bg-dark text-white p-3 pt-2'}> <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
                                                                                                                     className="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                            </svg> Tracking project </a> <br/>

                            <h2 className={'text-secondary lead mt-5'}>
                                legacy info
                            </h2>
                            <div className="information-links">
                                <a href="/information#privaci-policy" className={'text-decoration-none text-secondary nav-link'}>#privaci-policy</a>
                                <a href="/information#terms-of-use" className={'text-decoration-none text-secondary nav-link'}>#terms-of-use</a>
                                {/*<a href="/site-map" className={'text-decoration-none text-secondary nav-link'}>site map</a>*/}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>} link={'/'} linkName={''}/>

        <div className="text-end p-5">
            <a href="/cooperation" className={'text-decoration-none text-dark display-4'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                     className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                </svg> <b>get contact</b>
            </a>
        </div>

    </div>);
}

export default SectionQandA;
