

import React from 'react';
import {useTranslation} from "react-i18next";
import AccordionItemCustom from "../components/accordion";
import DataPivacyPolicy from "../data/privacy-policy";

function InfoPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'min-vh-100'}>


        <div className="container text-dark text-start pt-5 mt-5">
            <h1>Information</h1>

            <div className="row">
                <div className="col-md-8">
                    <p>
                        <a href="/information#privacy-policy" className={'text-decoration-none text-secondary'}>#privacy-policy</a> <br/>
                        <a href="/information#terms-of-use" className={'text-decoration-none text-secondary'}>#terms-of-use</a> <br/>
                        <a href="/site-map" className={'text-decoration-none text-secondary'}>site map</a>
                    </p>
                    <AccordionItemCustom data={[
                        {
                            id: 1,
                            title: "#privacy-policy",
                            content: <DataPivacyPolicy />
                        },
                        {
                            id: 1,
                            title: "#terms-of-use",
                            content: <div>
                                <b>Text</b>
                            </div>
                        }
                    ]} />

                   <div className="mt-5">
                       <h3>contacts</h3>
                       <p>
                         email:  <a href="mailto:yarik@lukyanchuk.com" className={'text-decoration-none text-secondary lead'}>yarik@lukyanchuk.com</a>
                       </p>
                       <p>
                           tel.: <a href="tel.: +380 (98) 075 13 57"  className={'text-decoration-none text-secondary lead'}>+380 (98) 075 13 57</a>
                       </p>
                       <p>
                           adress.: <a href="https://www.google.com/maps"  className={'text-decoration-none text-secondary lead'}>10001, Ukraine</a>
                       </p>
                   </div>
                </div>
                <div className="col-md-3">
                    <div className={'sticky-top'}>
                        <h5 className={'mt-5'}>payments</h5>
                        <img src="https://benya.pro/wp-content/uploads/2024/04/image.png" alt="payments" className={'rounded-2 shadow w-100'}/>

                        <h3 className={'mt-5'}>
                            USDT
                        </h3>
                        <small className="text-secondary">
                            TRC20
                        </small>
                        <p>
                            TVUAA7f5kjnVJtauKRb6GxW6DaWqkW8fbY
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>);
}

export default InfoPage;
