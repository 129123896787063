import {useState} from "react";

function FooterComponent (){

    const _dateYear = new Date().getFullYear();

    const _qrLinkImages = [
        {
            name: "telegram",
            qr: "/assets/img/qr/tg.png",
        },
        {
            name: "signal",
            qr: "/assets/img/qr/signal.png",
        },
        {
            name: "whatsapp",
            qr: "/assets/img/qr/whatsapp.png",
        },
    ];

    const [activeQr, setActiveQr] = useState('telegram')

    return (<footer className={'p-3'}>

        <div className="p-5 border rounded-5 shadow-lg bg-dark">

            <div className="row">
                <div className="col-md-6 col-12 text-start">
                    <h2 className={'text-white lead display-1'}>Yaroslav Lukyanchuk</h2>

                    <p className={'mt-5'}>
                        <a href="mailto:yarik@lukyanchuk.com" target={'_blank'} className={'footer-link text-decoration-none text-secondary lead display-'}>yarik@lukyanchuk.com</a>
                    </p>

                    <p>
                        <a href="tel.:+380 (98) 075 13 57" target={'_blank'} className={'footer-link text-decoration-none text-secondary lead display-'}>+380 (98) 075 13 57</a>
                    </p>

                    <p>
                        <a href="tel.:+380 (63) 075 13 57" target={'_blank'} className={'footer-link text-decoration-none text-secondary lead display-'}>+380 (63) 075 13 57</a>
                    </p>

                    <p>
                        <a href="https://yaroslav.lukyanchuk.com/links" target={'_blank'} className={'footer-link text-decoration-none text-secondary lead display-'}>
                            more links <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                                 className="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                            </svg> yaroslav.lukyanchuk.com/links
                        </a>
                    </p>

                </div>

                <div className="col-md-6 col-12 text-end">
                    <div className="qr-scan-contact__footer">
                        {
                            _qrLinkImages.filter((a) => a.name === activeQr).map((item, i) =>

                                <img id={'qr_footer'} src={item.qr} alt="qr" className={'img-fluid'}/>
                            )
                        }
                        <br/>
                        <div className="d-inline-flex justify-between align-items-center">
                            <small className={activeQr === 'telegram' ? 'text-white p-1' : 'text-secondary p-1'} onClick={()=>setActiveQr('telegram')}>
                                telegram
                            </small>
                            <small className={activeQr === 'signal' ? 'text-white p-1' : 'text-secondary p-1'} onClick={()=>setActiveQr('signal')}>
                                signal
                            </small>
                            <small className={activeQr === 'whatsapp' ? 'text-white p-1' : 'text-secondary p-1'} onClick={()=>setActiveQr('whatsapp')}>
                                whatsapp
                            </small>
                            <small className="text-secondary p-1 pb-2">
                                ...
                            </small>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <div className="p-5 d-flex justify-content-between align-items-end">

            <div className="copyright">
                <a href="/info" className={'text-decoration-none text-secondary'}>information</a>

                <p className={'mt-5'}>
                    © copyright {_dateYear}. Yaroslav Lukyanchuk
                </p>
            </div>

            <select name="" id="" className="text-secondary p-2 border rounded-5">
                <option value="5" selected>
                    <small>
                        v5.0
                    </small>
                </option>
                <option value="4">v.4.0</option>
                <option value="3">v.3.0</option>
                <option value="2">v.2.0</option>
                <option value="1">v.1.0</option>
            </select>
        </div>

    </footer>);
}

export default FooterComponent;
