
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'react-medium-image-zoom/dist/styles.css'
import '@splidejs/react-splide/css';

import PageHome from "./pages/home";
import ReactGA from 'react-ga';

import {Routes, Route, Link, Navigate} from "react-router-dom";
import BackToTop from "react-back-to-top-button";
import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import axios from "axios";
import PageNotFound from "./pages/404";
import PortfolioPage from "./pages/portfolio";
import SinglePortfolioPage from "./pages/portfolio/single";
import SingleServicesPage from "./pages/services/single";
import ServicesPage from "./pages/services";
import PageLayouts from "./components/layouts/page";
import InfoPage from "./pages/info";
import ContactsPage from "./pages/contacts";
import TrackingProjectPage from "./pages/tracking";
const TRACKING_ID = "UA-86660902-4"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {

    const { t, i18n } = useTranslation();

    // useEffect(() => {
    //     axios.post('https://api.lukyanchuk.com/api/v2/frontend/detected', [], {
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Accept": "application/json",
    //         }
    //     }).then((response) => {
    //         console.log(response.data)
    //     });
    //
    // })

    return (
    <div className="App">

        <Routes>

            {/*TODO: route data*/}
            {/*TODO: add helmet head tags npm*/}
            {/*TODO: cookie add*/}

            <Route index exact path={'/'} element={ <PageLayouts child={<PageHome/>} />}/>

            {/*<Route exact path={'/cooperation'} element={<OfferPage/>}/>*/}

            {/*<Route exact path={'/contacts'} element={<ContactsPage/>}/>*/}

            <Route exact path={'/services/'} element={<ServicesPage />}/>
            <Route exact path={'/services/:slug'} element={<SingleServicesPage />}/>
            <Route exact path={'/service/:slug'} element={<SingleServicesPage />}/>
            <Route exact path={'/s/:slug'} element={<SingleServicesPage />}/>

            <Route exact path={'/project/tracking'} element={<TrackingProjectPage />}/>

            {/*products pages as portfolio*/}

            {/*<Route exact path={'/portfolio/tag/:name'} element={<PortfolioPage type={'tag'}/>}/>*/}
            {/*<Route exact path={'/portfolio/service/:name'} element={<PortfolioPage type={'service'}/>}/>*/}
            <Route exact path={'/portfolio'} element={<PortfolioPage type={'default'} />}/>
            <Route exact path={'/project/:slug'} element={<SinglePortfolioPage />}/>
            <Route exact path={'/projects/:slug'} element={<SinglePortfolioPage />}/>
            <Route exact path={'/portfolio/:slug'} element={<SinglePortfolioPage />}/>

            {/*<Route exact path={'/thank-you'} element={<ThankYouPage/>}/>*/}
            {/*<Route exact path={'/site-map'} element={<PageMapSite/>}/>*/}
            {/*<Route exact path={'/information'} element={<PageInformation/>}/>*/}

            <Route exact path={'/mobile-application'} element={<div/>}/>
            <Route exact path={'/mobile-app'} element={<div/>}/>

            <Route exact path={'/info'} element={<PageLayouts child={<InfoPage/>} />}/>
            <Route exact path={'/information'} element={<PageLayouts child={<InfoPage/>} />}/>

            <Route exact path={'/contacts'} element={<PageLayouts child={<ContactsPage/>} />}/>

            <Route path="*" element={<PageNotFound/>}/>
        </Routes>

        <BackToTop
            showOnScrollUp
            showAt={100}
            speed={1500}
            easing="easeInOutQuint"
        >
            <div className="button-top d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                     className="bi bi-arrow-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
                </svg>
                {/*<span className={''}>Top</span>*/}
            </div>
        </BackToTop>
    </div>
  );
}

export default App;
