

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../../components/section";
import {MouseParallax} from "react-just-parallax";
import '../css/book.css';
import BooksSection from "./book";
import {MotionAnimate} from "react-motion-animate";
import * as FaIcons from "react-icons/fa";

function SectionProducts(props) {

    const { t, i18n } = useTranslation()

    return (<div id={'products'}>

        <div className="text-center">
            <div id={'whtat-todo__title-position'} className="p-5">
                <small className="text-secondary">
                    {/*products*/}
                    books, apps & more
                </small>

                <h2 className={'display-1'}>
                    {/*Books & Apps*/}
                    Products
                </h2>

            </div>
        </div>

        <Section title={''} name={''} child={<div className={'rounded-5 bg-white text-white'}>

            <div className="container -fluid">
                <div className="d-flex flex-wrap justify-content-between align-items-start">

                    <div className="col-md-6 col-12 p-2">
                        <div className="border rounded-5 cover1" style={{height: '50vh'}}>
                            {/*<img src="https://growthukraine.com/assets/app/app3.png" alt="iphone" className={'img-fluid'}/>*/}
                            {/*<img src="https://apps.lukyanchuk.com/image/iphoneNEW.png" alt="iphone" className={'img-fluid'}/>*/}
                            <div className="p-5">
                                <FaIcons.FaAppStore color={'black'} size={58} />
                            </div>
                            <h2 className={'text-dark p-5 pb-1'}>Something new</h2>
                            <p className={'text-dark p-5 pt-0'}>Coming soon...</p>
                        </div>
                    </div>

                    <div className="col-md-6 col-12 p-2">
                        <div className="border rounded-5 cover1 align-items-center justify-content-center d-flex flex-wrap bg-dark" style={{height: '50vh'}}>

                            <div className="col-md-6 col-12 text-center">
                               <div className="text-start p-5">
                                   <img src="https://office.lukyanchuk.com/storage/files/portfolio/8ihYr6s9pJUSb7B3BaAFnYzVLskerXkASPJti8aX.png" alt="logo" className={'rounded shadow-lg'} width={'50'}/>
                                   <h3 className={'mt-3'}>Random App</h3>
                                   <p>
                                       Simple app for creators
                                   </p>
                                   <img src="https://growthukraine.com/assets/app/android.svg" alt=""/>
                                   <a href="/" className={'mt-2 text-decoration-none text-white p-2'}>download</a>
                               </div>
                            </div>
                            <div className="col-md-6 col-12 text-center">
                                <MotionAnimate
                                    animation='fadeInUp'
                                    reset={true}
                                    distance={100}
                                    delay={1}
                                    speed={1}>
                                    <img src="https://apps.lukyanchuk.com/image/iphoneNEW.png" alt="iphone" className={'img-fluid'}/>
                                </MotionAnimate>
                            </div>

                            {/*<img src="https://tehnomag.com/upload/catalog/product/5020/thumb/apple-iphone-14-plus-blue-a_63516ea26f734_650xr.png" alt="iphone" className={'img-fluid rounded-5'}/>*/}
                        </div>
                    </div>

                    <div className="col-md-12 col-12 p-2">
                        <div className="border rounded-5" style={{height: '55vh'}}>

                            <BooksSection />

                        </div>
                    </div>


                    <div className="col-md-12 col-12 p-2">
                        <div className="p-3 rounded-5 text-dark" style={{height: '50vh'}}>


                                <div id="gift"
                                     className="col-md-12 d-flex row justify-content-between align-items-start bg-dark rounded-5 shadow-lg p-5">
                                    <div className="col-md-8 p-md-5 p-0">
                                        <div>
                                            <div style={{opacity: "1", transform: "none"}}><h2
                                                className="custom-heading display-4 text-white w-auto"><b>Блокнот
                                                продуктивності</b></h2></div>
                                        </div>
                                        <div>
                                            <div style={{opacity: "1", transform: "none"}}><p
                                                className="p-md-2 p-0 display- text-white">Блокнот підвищіть твою
                                                продуктивність, дасть тобі змогу сфокусуватись на дійсно потрібних для
                                                тебе речех та справах, відкинувши все зайве.</p></div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-md-8">
                                                <div>
                                                    <div style={{opacity: "1", transform: "none"}}>
                                                        <div className="row"><a
                                                            href="https://docs.google.com/forms/d/e/1FAIpQLSc5nJLkAQ1NBxCQc3JC4oYBjWcGLiCmqa_KU1swwCUy1XUx9w/viewform?usp=sf_link"
                                                            className="btn btn-outline-light shadow-lg bg-dark text-white p-2 pt-3 pb-3 col-md-8 col-12 rounded-5"><span
                                                            className="lead display-">🎁   Отримати</span></a><a
                                                            href="/NOTEBOOK_template.pdf"
                                                            className="btn text-white p-2 pt-3 pb-3 col-md-4 col-12 rounded-5">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                 height="16" fill="currentColor"
                                                                 className="bi bi-download" viewBox="0 0 16 16">
                                                                <path
                                                                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"></path>
                                                                <path
                                                                    d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"></path>
                                                            </svg>
                                                            <small className={'p-2'}>шаблон</small></a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 d-none d-md-block text-center">
                                        <div>
                                            <div style={{opacity: "1", transform: "none"}}><img
                                                src="https://static.vecteezy.com/system/resources/previews/009/376/176/original/3d-black-book-illustration-png.png"
                                                height="350" alt="photo" className="img-fluid1 rounded" /></div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <a href="https://books.lukyanchuk.com/NOTEBOOK_template_v1.pdf" className={'text-decoration-none text-dark d-flex align-items-center'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         className="bi bi-download" viewBox="0 0 16 16">
                                        <path
                                            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"></path>
                                        <path
                                            d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"></path>
                                    </svg>
                                    <small className={'p-2'} style={{fontSize: '18px'}}>Блокнот продуктивності v1.0 (2023)</small>
                                </a>


                        </div>
                    </div>

                </div>
            </div>

        </div>} link={'/'} linkName={''}/>

        {/*<div className="text-end p-5">*/}
        {/*    <a href="/cooperation" className={'text-decoration-none text-dark display-4'}>*/}
        {/*        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"*/}
        {/*             className="bi bi-arrow-right" viewBox="0 0 16 16">*/}
        {/*            <path fill-rule="evenodd"*/}
        {/*                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>*/}
        {/*        </svg> <b>get contact</b>*/}
        {/*    </a>*/}
        {/*</div>*/}

    </div>);
}

export default SectionProducts;
