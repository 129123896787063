import React, { useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';

const BLUE = 'rgb(204,246,204)';
const PINK = 'rgb(248,198,198)';

function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <button
            type="button"
            className={'btn btn-light'}
            // style={{ backgroundColor: isCurrentEventKey ? PINK : BLUE }}
            onClick={decoratedOnClick}
        >
            {/*{children}*/}
            {isCurrentEventKey ? 'close' : 'open'}
        </button>
    );
}

function AccordionItemCustom(props) {

    const data = props.data;

    return (
        <Accordion defaultActiveKey="0">
            {/*<Card className={'border-0'}>*/}
            {/*    <Card.Header className={'d-flex justify-content-between align-items-center bg-white'}>*/}
            {/*        <h3>Title question</h3>*/}
            {/*        <ContextAwareToggle eventKey="0">answer</ContextAwareToggle>*/}
            {/*    </Card.Header>*/}
            {/*    <Accordion.Collapse eventKey="0">*/}
            {/*        <Card.Body>Hello! I am the body</Card.Body>*/}
            {/*    </Accordion.Collapse>*/}
            {/*</Card>*/}
            {/*<Card className={'border-0'}>*/}
            {/*    <Card.Header className={'d-flex justify-content-between align-items-center bg-white'}>*/}
            {/*        <h3>Title question</h3>*/}
            {/*        <ContextAwareToggle eventKey="1">read</ContextAwareToggle>*/}
            {/*    </Card.Header>*/}
            {/*    <Accordion.Collapse eventKey="1">*/}
            {/*        <Card.Body>Hello! I am the body</Card.Body>*/}
            {/*    </Accordion.Collapse>*/}
            {/*</Card>*/}
            {
                data && data.map((card, i) =>  <Card key={i} className={'border-0 mt-5 pt-2'}>
                    <Card.Header className={'d-flex justify-content-between align-items-center bg-white'}>
                        <h3 id={card.title.split('#')[1]}>{card.title}</h3>
                        <ContextAwareToggle eventKey={i}>read</ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={i}>
                        <Card.Body>
                            {card.content}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>)
            }
        </Accordion>
    );
}

export default AccordionItemCustom;
