

import React from 'react';
import {useTranslation} from "react-i18next";
import SectionServices from "../../components/section/services";

function ServicesPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'vh-100 pt-5'}>

        <div className="text-center">
            <div id={'whtat-todo__title-position'} className="p-5">
                <small className="text-secondary">
                    послуги
                </small>

                <h2 className={'display-1'}>
                    Що я можу вам запропонувати
                </h2>

            </div>
        </div>


        <div className="container-fluid text-dark text-start pt-5 mt-5">
            <div className="p-5 d-flex flex-wrap justify-content-between align-items-start">

                <div className="col-md-6 col-6 p-2 text-center">
                    <div className="service-item hover-div border rounded-5">
                        <img src="https://tehnomag.com/upload/catalog/product/5020/thumb/apple-iphone-14-plus-blue-a_63516ea26f734_650xr.png" alt="iphone" className={'rounded-5 img-fluid'}/>
                    </div>
                    <h2 className={'mt-2'}>
                        Мобільна розробка
                    </h2>
                </div>

                <div className="col-md-6 col-6 p-2 text-center">
                    <div className="service-item hover-div border rounded-5">
                        <img src="https://www.agilecomputers.co.uk/wp-content/uploads/business-it-support-specialists-image-1.jpg" alt="macbook" className={'rounded-5 img-fluid'}/>
                    </div>
                    <h2 className={'mt-2'}>
                        Веб розробка
                    </h2>
                </div>

                <div className="col-md-6 col-6 p-2 text-center">
                    <div className="service-item hover-div border rounded-5">
                        <img src="https://www.mediaheroes.com.au/wp-content/uploads/2023/09/graphic-design-styles-media-heroes-banner.webp" alt="macbook" className={'rounded-5 img-fluid'}/>
                    </div>
                    <h2 className={'mt-2'}>
                        Дизайн
                    </h2>
                </div>

                <div className="col-md-6 col-6 p-2 text-center">
                    <div className="service-item hover-div border rounded-5">
                        <img src="https://www.liveagent.com/app/uploads/2024/03/help-desk-metrics-feature-img.jpg" alt="macbook" className={'rounded-5 img-fluid'}/>
                    </div>
                    <h2 className={'mt-2'}>
                        Підтримка та супровід
                    </h2>
                </div>

            </div>

            <div className="text-end p-5">
                <a href="/services" className={'text-decoration-none text-dark display-4'}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                         className="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                    </svg> <b>get connect</b>
                </a>
            </div>
        </div>
    </div>);
}

export default ServicesPage;
