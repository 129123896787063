import LanguageSwitch from "../language";
import {useTranslation} from "react-i18next";
import {MotionAnimate} from 'react-motion-animate'
import {DarkModeSwitch} from 'react-toggle-dark-mode';
import React, {useEffect, useState} from "react";
import SectionAbout from "./about";

function SectionMain({props}) {

    const { t, i18n } = useTranslation()

    const [isDarkMode, setDarkMode] = useState(false);

    const toggleDarkMode = (checked) => {
        setDarkMode(checked);
    };

    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    }, [isDarkMode]);

    return <div>
        <section className="main-section pt-5">
            <div id={'main'} className="d-flex flex-wrap min-vh-100">

                <div className="col-md-12 col-12 text-center">
                    <h1 id={'main_title_text'} className={'text-uppercase display-1'}>lukyanchuk</h1>
                </div>

                <div className="col-md-6 col-12 text-md-start text-end">
                    {/*style={{paddingLeft: '120px'}}>*/}
                    <a href="#what-i-do" id={'main_link'} className={'text-decoration-none text-dark p-md-5 p-4 '}>
                        <small className={''}>
                            {/*Hit me up*/}
                            Start
                        </small>
                         <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                       xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"
                                       viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M19 12H5m14 0-4 4m4-4-4-4"/>
                    </svg>
                    </a>

                    {/*<LanguageSwitch  color={'dark'}/>*/}
                </div>
                <div className="col-md-6 col-12">
                    <p id={'main_text'}>
                        I'm a software designer with a passion for creating interfaces with a focus on simplicity and clarity.
                    </p>
                    <div className="d-flex">
                        <small className="text-dark email">
                            yarik@lukyanchuk.com
                        </small>
                        {/*<a href="https://resume.lukyanchuk.com" id={'resume_link'} target={'_blank'} className={'p-3 text-decoration-none text-dark d-flex align-items-center'}>*/}

                        {/*    <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"*/}
                        {/*         xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"*/}
                        {/*         viewBox="0 0 24 24">*/}
                        {/*        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"*/}
                        {/*              d="M19 12H5m14 0-4 4m4-4-4-4"/>*/}
                        {/*    </svg>*/}
                        {/*    <small className="text-secondary">*/}
                        {/*        Resume*/}
                        {/*    </small>*/}
                        {/*</a>*/}
                    </div>
                </div>

            </div>

        </section>

        {/*<div className="text-end p-5 fixed-bottom" style={{marginRight: "5vw"}}>*/}
        {/*    <a href="/services" className={'text-decoration-none text-dark lead  bg-warning shadow-sm p-3'}>*/}
        {/*        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"*/}
        {/*             className="bi bi-arrow-right" viewBox="0 0 16 16">*/}
        {/*            <path fill-rule="evenodd"*/}
        {/*                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>*/}
        {/*        </svg> <b>Donate</b>*/}
        {/*    </a>*/}
        {/*</div>*/}
    </div>;
}

export default SectionMain;
