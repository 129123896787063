

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../../components/section";

function SectionPartners(props) {

    const { t, i18n } = useTranslation()

    return (<div>

        <div className="text-center">
            <div id={'whtat-todo__title-position'} className="p-5">
                <small className="text-secondary">
                    client & partners
                </small>

                <h2 className={'display-1'}>
                    Trust me & him
                </h2>

            </div>
        </div>

        <Section title={''} name={''} child={<div className={'rounded-5 bg-white shadow-lg- text-white border bg-dark1'}>

            <div className="container m-5 m-auto p-5 text-start">

                <div className="d-flex flex-wrap align-items-center mb-5 mt-3">

                        <div className={'col-md-4 col-6 text-center'}>
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdNhMBTNZQpjIjVxVUuRgpanzFTXC84I4hNQ&s" alt="АКБ Крамниця" className={'img-fluid p-md-5 p-1'}/>
                            <br/>
                            <small className="text-secondary">
                                АКБ Крамниця
                            </small>
                        </div>

                        <div className={'col-md-4 col-6 text-center'}>
                            <img src="https://company-logo-frankfurt.rabota.ua/cdn-cgi/image/w=237/12974203_20221125190316.jpeg" alt="Flutter" className={'img-fluid p-md-5 p-1'}/>
                            <small className="text-secondary">
                                <br/>
                                LikeWork HR Partners
                            </small>
                        </div>

                        <div className={'col-md-4 col-12 text-center'}>
                            <img src="https://ukrsocks.ua/image/catalog/logo-2.png" alt="react.js" className={'img-fluid p-md-5 p-1'}/>
                            <br/>
                            <small className="text-secondary">
                                Ukrsocks.ua
                            </small>
                        </div>

                    </div>

            </div>

        </div>} link={'/'} linkName={''}/>

        <div className="text-end p-5">
            <a href="/cooperation" className={'text-decoration-none text-dark display-4'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                     className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                </svg> <b>get contact</b>
            </a>
        </div>

    </div>);
}

export default SectionPartners;
