

import React from 'react';
import {useTranslation} from "react-i18next";

function PageNotFound(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'vh-100 pt-5'}>

        <div className="container text-dark text-start pt-5 mt-5">

            <h1 className={'title-section'}>Ooops...</h1>

            <p className="lead">
                404. That’s an error. <br/>
                The requested URL / 404 was not found on this server. <br/>
                That’s all we know.
            </p>

            <div className="pt-5 pb-5 col-md-2 col-12 text-center">

                <a href="/" className="border shadow-sm rounded-5 p-3 nav-link">Go to Home</a>

            </div>
        </div>
    </div>);
}

export default PageNotFound;
