

import React from 'react';
import {useTranslation} from "react-i18next";
import CardPortfolioService from "../../components/card_portfolio_service";
import CardPortfolio from "../../components/card_portfolio";

function PortfolioPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'vh-100 pt-5'}>

        <div className="text-center">
            <div id={'whtat-todo__title-position'} className="p-5">
                <small className="text-secondary">
                    portfolio
                </small>

                <h2 className={'display-1'}>
                    Project work
                </h2>

            </div>

            <div className="option-portfolio-view container row m-auto">

                <div className="d-flex flex-wrap align-items-center justify-content-center">
                    <div className="item p-1 col-auto m-1">
                        <small className="text-secondary border rounded-5 p-2">
                            Web develop
                        </small>
                    </div>

                    <div className="item p-1 col-auto m-1">
                        <small className="text-secondary border rounded-5 p-2">
                            Mobile develop
                        </small>
                    </div>

                    <div className="item p-1 col-auto m-1">
                        <small className="text-secondary border rounded-5 p-2">
                            Design
                        </small>
                    </div>

                    <div className="item p-1 col-auto m-2">
                        <small className="text-secondary border rounded-5 p-2">
                            Other
                        </small>
                    </div>
                </div>

                {/*<div className={'option-category col-md-3'}>*/}

                {/*    <select name="" id="" className={'border rounded-5 p-3 pt-2 pb-2'}>*/}
                {/*        <option value="">Apps</option>*/}
                {/*        <option value="">CMS</option>*/}
                {/*        <option value="">CRM</option>*/}
                {/*        <option value="">Landing</option>*/}
                {/*        <option value="">Web-site</option>*/}
                {/*        <option value="">Design</option>*/}
                {/*    </select>*/}
                {/*    <br/>*/}
                {/*    <small className="text-secondary">*/}
                {/*        <i>Category</i>*/}
                {/*    </small>*/}
                {/*</div>*/}

                {/*<div className="optional-search col-md-6">*/}
                {/*    <input type="text" className="border rounded-5 p-3 pt-2 pb-2 w-100" placeholder={'Query...'}/>*/}
                {/*    <br/>*/}
                {/*    <small className="text-secondary">*/}
                {/*        <i>Search by title, tag, description</i>*/}
                {/*    </small>*/}
                {/*</div>*/}

                {/*<div className={'option-category col-md-3'}>*/}

                {/*    <select name="" id="" className={'border rounded-5 p-3 pt-2 pb-2'}>*/}
                {/*        <option value="">Mobile develop</option>*/}
                {/*        <option value="">Web develop</option>*/}
                {/*        <option value="">Design</option>*/}
                {/*        <option value="">Support & services</option>*/}
                {/*    </select>*/}
                {/*    <br/>*/}
                {/*    <small className="text-secondary">*/}
                {/*        <i>Service</i>*/}
                {/*    </small>*/}
                {/*</div>*/}

            </div>
        </div>

        <div className="container-fluid text-dark text-start pt-5 mt-51">

            <div className="d-flex flex-wrap align-items-center mt-51 p-3">

                <CardPortfolio />

                {/*<CardPortfolioService />*/}

                {/*<CardPortfolioService />*/}

                {/*<CardPortfolioService />*/}

                {/*<CardPortfolioService />*/}

                {/*<CardPortfolioService />*/}

            </div>

        </div>

        <div className="text-end p-5 fixed-bottom">
            <a href="/services" className={'text-decoration-none text-dark display-4  bg-white shadow-sm rounded-5 p-3'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                     className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                </svg> <b>get cooperation</b>
            </a>
        </div>
    </div>);
}

export default PortfolioPage;
