

import React from 'react';
import {useTranslation} from "react-i18next";
import Section from "../../components/section";

function SectionStackTechnologies(props) {

    const { t, i18n } = useTranslation()

    return (<div id={'stack-technologies'}>

        {/*<div className="text-center">*/}
        {/*    <div id={'whtat-todo__title-position'} className="p-5">*/}
        {/*        <small className="text-secondary">*/}
        {/*            stack-technologies*/}
        {/*        </small>*/}

        {/*        <h2 className={'display-1'}>*/}
        {/*            What i am use for developers*/}
        {/*        </h2>*/}

        {/*    </div>*/}
        {/*</div>*/}

        <Section title={''} name={''} child={<div className={'rounded-5 bg-dark text-white shadow-lg'}>

            <div className="container m-5 m-auto container p-5 text-start ">

                <div className="d-flex align-items-center">
                    <h5 className={'lead display-'}>
                        {/*{t('partners.title')}*/}
                        Technologies <span className={'p-1'}></span>
                    </h5>
                    <hr width={'80%'} style={{color: "white"}} />
                </div>

                <div className="d-flex flex-wrap mt-5 align-items-center">

                    <div id={'list-technologies'} className={'bg-white1 d-flex flex-wrap justify-content-between align-items-center p-1 pb-4'}>
                        <div className={'col-md-2 col-2 text-center'}>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Laravel.svg/1200px-Laravel.svg.png" alt="Laravel" className={'img-fluid p-md-5 p-1 img-stack-technologies'}/>
                            {/*<br/>*/}
                            {/*Laravel*/}
                        </div>

                        <div className={'col-md-2 col-2 text-center'}>
                            <img src="https://plugins.jetbrains.com/files/9212/376589/icon/pluginIcon.png" alt="Flutter" className={'img-fluid p-md-5 p-1 img-stack-technologies'}/>
                            {/*<br/>*/}
                            {/*Flutter*/}
                        </div>

                        <div className={'col-md-2 col-2 text-center'}>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png" alt="react.js" className={'img-fluid p-md-5 p-1 img-stack-technologies'}/>
                            {/*<br/>*/}
                            {/*React.JS*/}
                        </div>

                        {/*<div className={'col-md-2 text-center'}>*/}
                        {/*    <img src="https://static-00.iconduck.com/assets.00/nextjs-icon-2048x1234-pqycciiu.png" alt="next.js" className={'img-fluid p-2'}/>*/}
                        {/*    /!*<br/>*!/*/}
                        {/*    /!*React.JS*!/*/}
                        {/*</div>*/}

                        <div className={'col-md-2 col-2 text-center'}>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/1200px-Bootstrap_logo.svg.png" alt="Bootstrap" className={'img-fluid p-md-5 p-1 img-stack-technologies'}/>
                            {/*<br/>*/}
                            {/*Bootstrap*/}
                        </div>

                        {/*<div className={'col-md-2 text-center'}>*/}
                        {/*    <img src="https://cdn4.iconfinder.com/data/icons/social-media-logos-6/512/23-swift-512.png" alt="Bootstrap" className={'img-fluid p-5'}/>*/}
                        {/*    /!*<br/>*!/*/}
                        {/*    /!*Bootstrap*!/*/}
                        {/*</div>*/}

                        <div className={'col-md-2 col-2 text-center'}>
                            <img src="https://www.simplilearn.com/ice9/free_resources_article_thumb/MySQL-Logo.wine.png" alt="MySQL" className={'img-fluid p-md-5 p-1 img-stack-technologies'}/>
                            {/*<br/>*/}
                            {/*MySQL*/}
                        </div>

                        <div className={'col-md-2 col-2 text-center'}>
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Git_icon.svg/2048px-Git_icon.svg.png" alt="Git" className={'img-fluid p-md-5 p-1 img-stack-technologies'}/>
                        </div>

                        {/*<div className={'col-md-2 text-center'}>*/}
                        {/*    <img src="https://www.mamp.info/images/icons/mamp-pro.png" alt="Git" className={'img-fluid p-5'}/>*/}
                        {/*</div>*/}

                        {/*<div className={'col-md-2 text-center'}>*/}
                        {/*    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/1200px-Visual_Studio_Code_1.35_icon.svg.png" alt="Git" className={'img-fluid p-5'}/>*/}
                        {/*</div>*/}
                    </div>

                </div>

            </div>

        </div>} link={'/'} linkName={''}/>

        {/*<div className="text-end p-5">*/}
        {/*    <a href="/cooperation" className={'text-decoration-none text-dark display-4'}>*/}
        {/*        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"*/}
        {/*             className="bi bi-arrow-right" viewBox="0 0 16 16">*/}
        {/*            <path fill-rule="evenodd"*/}
        {/*                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>*/}
        {/*        </svg> <b>get contact</b>*/}
        {/*    </a>*/}
        {/*</div>*/}

    </div>);
}

export default SectionStackTechnologies;
