import React from "react";

function DataPivacyPolicy(){
    return <div id={'privacy-policy-'}>
        <h3>Вступ.</h3>
        <p>
            В lukyanchuk.com ми розуміємо цінність персональних даних. Тому ми підготували для вас цю Політику конфіденційності. Тут ви можете знайти інформацію про те, як ми обробляємо ваші дані, для яких цілей використовуємо, а також які у вас права щодо персональних даних.
        </p>

       <p>
           Якщо у вас виникнуть запитання щодо цієї Політики або ваших персональних даних, ви можете написати нам на yarik@lukyanchuk.com.
       </p>

        <h2>Загальні положення.</h2>
        <p>
            Ця Політика конфіденційності (далі – Політика) є юридичною угодою (далі – Угода) між інформаційним ресурсом lukyanchuk.com (далі – lukyanchuk.com Компанія або ми) і вами в ролі користувача ( далі – ви, Користувач). Угода застосовується до нашого Сайту, який знаходиться за адресою https://lukyanchuk.com// (далі – Сайт).
        </p>

        <h2>
            Збір індивідуальних даних.
        </h2>
        <p>
            Коли ви заходите на наш Сайт, ми автоматично отримуємо певні технічні дані, ви можете надати деякі дані на добровільній основі. У цьому розділі ми пояснюємо, які дані ми збираємо.
        </p>
        <br/>
        Категорії персональних даних	Опис категорії для Сайту
        2.1. Дані, що збираються автоматично
        Коли ви заходите на наш Сайт, деякі дані збираються автоматично. Нам потрібні технічні дані для роботи Сайту, а також для його підтримки та покращення. Такі дані містять:
        <br/>
        Cookie та аналогічні технології. Ми використовуємо файли cookie для аналітичних цілей і надання вам функціоналу нашого Сайту.
        <br/>
        Технічні дані. При вході на сайт ми автоматично отримуємо вашу IP-адресу.
        <br/>
        2.2. Дані, які Ви передаєте
        На нашому сайті ви можете самостійно залишити нам свої персональні дані.
        <br/>
        При реєстрації на форумі, сайті, в межах заходів, що проводяться адміністрацією сайту, в опитуваннях, заявках, формах зворотного зв’язку шляхом внесення записів до реєстраційних онлайн-форм.
        <br/>
        Мета оброблення персональних даних.
        Початковою метою і, відповідно, причиною оброблення ваших даних є надання вам доступу до нашого Сайту. Однак мета збирання даних може змінюватися відповідно до пункту 2.2. цієї Політики. Також ми обробляємо ваші дані для покращення роботи нашого Сайту та для аналітичних цілей. Обробка ваших даних, яка прямо не стосується надання доступу до Сайту (покращення роботи Сайту, аналітика), але стикається з нею, відбувається на підставі нашого законного інтересу.
        <br/>
        У разі виникнення нової причини оброблення персональних даних ми попросимо вашу попередню згоду. Ви маєте право відкликати свою згоду в будь-який зручний для вас момент, написавши нам на i@lukyanchuk.com.
        <br/>
        Передавання даних.
        У ході нашої роботи ми можемо передавати третім сторонам певні дані про вас.

        4.1. Постачальники послуг. Ми можемо передавати ваші персональні дані нашим партнерам або контрагентам, з якими в нас укладені відповідні договори.

        4.2. За вашою згодою. У тих випадках, коли ви дали свою згоду, ми можемо передавати ваші особисті дані, як описано в момент отримання згоди.

        4.3. Реструктуризація. Ми не продаємо ваші дані будь-якій компанії чи організації. Проте ми можемо передати ваші персональні дані правонаступнику внаслідок злиття, консолідації чи іншої корпоративної реорганізації, у якій ми можемо взяти участь. У такому разі ми повідомимо вас про те, що ваші особисті дані будуть передані і на них поширюватиметься інша політика конфіденційності.

        4.4. Виконання норм законодавства, безпека. Ми можемо передати ваші персональні дані третім особам, якщо це необхідно:

        для виконання урядового запиту, ухвали суду або застосовного законодавства;
        для захисту від претензій третіх сторін;
        для запобігання шахрайству, для розслідувань випадків шахрайства.
        Захист ваших персональних даних.
        Ми використовуємо різноманітні заходи безпеки для захисту персональних даних, які ми обробляємо. Ми впровадили розумні операційні та технічні заходи для обмеження доступу до ваших даних. Наприклад, дані, які ми отримуємо, доступні лише уповноваженим співробітникам і підрядникам. Якщо ви маєте підстави вважати, що ваша взаємодія з нашим Сайтом не є безпечною, негайно повідомте нам про це, зв’язавшись з нами через i@lukyanchuk.com.

        <h2>Політика щодо неповнолітніх.</h2>
        <p>
            Наш Сайт не розрахований на осіб, які не досягли 16 років. У випадку, якщо ви є батьком або опікуном особи до 16 років і ви дізналися, що неповнолітній передав нам свої дані, будь ласка, зв’яжіться з нами за адресою i@lukyanchuk.com, і ми зробимо все, щоб максимально швидко видалити ці дані.
        </p>

        <h2>Використання файлів cookie.</h2>
        <p>
            Файл cookie – це файл, що зберігається на жорсткому диску користувача і містить інформацію про користувача. Як правило, ми використовуємо сесійні файли cookie, щоб зберегти ваші вподобання, і термін дії таких файлів cookie закінчується при закритті браузера. Файли cookie також можуть бути аналітичними. Дані, що збираються з допомогою файлів cookie, можуть містити:
        </p>

        вебсайт, з якого ви потрапили на наш Сайт;
        вебсторінки, які ви переглядали на нашому Сайті;
        рекламні оголошення, які ви переглядали та натискали під час перегляду різних вебсайтів;
        ми також збираємо інформацію, використовуючи вебмаяки (також відомі як пікселі відстеження).
        Ви можете вимкнути всі cookie. Щоб це зробити, відкрийте налаштування браузера. Посилання нижче надають інформацію про налаштування cookie для браузера:

        Internet Explorer
        Firefox
        Microsoft Edge
        Chrome
        Safari
        Крім цього, ви можете відключити файли cookie, відвідавши Network Advertising Initiative Opt Out Page.

        Права щодо персональних даних.
        Якщо ви хочете отримати доступ, переглянути, оновити, виправити та видалити будь-які персональні дані, які ми зберігаємо про вас, або скористатися будь-якими іншими вашими правами, зокрема правом обмежити оброблення даних, правом відмовитись від оброблення, а також правом на перенесення даних. Для цього ви можете надіслати нам листа за адресою i@lukyanchuk.com. Якщо ви незадоволені тим, як ми обробляємо ваші дані, ви маєте право подати скаргу до регулювального органу.

        Зберігання персональних даних.
        Ми зберігаємо дані, які дозволяють нам прямо чи опосередковано вас ідентифікувати доти, доки вони необхідні нам для виконання цілей збору даних, відповідно до пункту 3 цієї Політики та відповідно до вимог законодавства.

        Передавання даних.
        У процесі нашої діяльності нам може знадобитися обробити ваші дані за межами країни, де ви проживаєте. У такому разі ми будемо використовувати відповідні запобіжні заходи (наприклад, договірні положення про захист даних), щоб забезпечити захист ваших персональних даних відповідно до цієї Політики.

        Зміна Політики.
        Застосовне законодавство та наша практика згодом можуть змінитися. У разі оновлення Політики ми опублікуємо зміни на нашому Сайті. Якщо ми істотно змінимо спосіб оброблення ваших персональних даних, ми надамо вам попереднє повідомлення або, якщо це потрібно за законом, запитаємо вашу згоду щодо внесення таких змін. Ми рекомендуємо регулярно читати нашу Політику, щоб знати свої права щодо своїх персональних даних.
    </div>;
}

export default DataPivacyPolicy;
