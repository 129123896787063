import React from "react";

import FooterComponent from "../footer";
import HeaderComponent from "../header";

function PageLayouts(props) {
    return (
      <div className="page-layout container-fluid">

          <HeaderComponent />

          <main className={'min-vh-100'}>

              {
                  props.child
              }

          </main>

          <FooterComponent />

      </div>
    );
};

export default PageLayouts;
