

import React from 'react';
import {useTranslation} from "react-i18next";
import SectionPortfolio from "../../components/section/portfolio";
import CardPortfolio from "../../components/card_portfolio";
import CardPortfolioService from "../../components/card_portfolio_service";

function SingleServicesPage(props) {

    const { t, i18n } = useTranslation()

    return (<div className={'vh-100 pt-5'}>

        <div className="text-center">
            <div id={'whtat-todo__title-position'} className="p-5">
                <small className="text-secondary">
                    service
                </small>

                <h2 className={'display-1'}>
                    Mobile develop
                </h2>

            </div>
        </div>

        <div className="container-fluid text-dark text-start pt-5 mt-5">

            <div className="container row m-auto">
                <div className="col-md-6">
                    <img src="https://tehnomag.com/upload/catalog/product/5020/thumb/apple-iphone-14-plus-blue-a_63516ea26f734_650xr.png" alt="iphone" className={'img-fluid'}/>
                </div>
                <div className="col-md-6">
                    <h1>
                        Mobile develop
                    </h1>
                    <small className="text-secondary">
                        using flutter
                    </small>
                    <p className="mt-5">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur dignissimos distinctio explicabo necessitatibus numquam porro quisquam quos sunt tempore, totam? Dolores earum expedita, illo laborum quibusdam quis quisquam ut veniam!
                    </p>

                    <p className="mt-5">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur dignissimos distinctio explicabo necessitatibus numquam porro quisquam quos sunt tempore, totam? Dolores earum expedita, illo laborum quibusdam quis quisquam ut veniam!
                    </p>

                    <p className="mt-5">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequuntur dignissimos distinctio explicabo necessitatibus numquam porro quisquam quos sunt tempore, totam? Dolores earum expedita, illo laborum quibusdam quis quisquam ut veniam!
                    </p>

                    <div className="mt-5">
                        <a href="/services" className={'text-decoration-none text-dark display-4'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                                 className="bi bi-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                            </svg> <b>get connect</b>
                        </a>
                    </div>
                </div>
            </div>

            <div className="example-service-project-work mt-5">

                <div className="container">
                    <h2 className={'display-4'}>Example project work</h2>
                </div>

                {/*TODO: add type show portfolio by category/service + limit*/}
                <div className="d-flex flex-wrap align-items-center mt-5">

                    <CardPortfolioService />

                    <CardPortfolioService />

                    <CardPortfolioService />

                    <CardPortfolioService />

                    <CardPortfolioService />

                    <CardPortfolioService />

                </div>
            </div>

        </div>

        <div className="text-end p-5">
            <a href="/services" className={'text-decoration-none text-dark display-4'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                     className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                </svg> <b>get contact</b>
            </a>
        </div>
    </div>);
}

export default SingleServicesPage;
