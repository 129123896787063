import React, {useState} from "react";
import Navbar from "./menu/nav";
import LanguageSwitch from "./language";

function HeaderComponent (){

    const _dateYear = new Date().getFullYear();

    return (<header className={''}>

        {/*<Navbar />*/}

        <div className="fixed-top bg-white p-4 d-flex justify-content-between align-items-center">
            <div className="logo">
                <a href="/" className={'nav-link'}>
                    <h2>Y. L.</h2>
                </a>
            </div>
            <div className="menu d-none d-md-block">
                <nav>
                    <ul className={'nav'}>
                        {/*<li className={''}>*/}
                        {/*    <a href="/" className={'nav-link text-secondary text-decoration-none'}>Home</a>*/}
                        {/*</li>*/}
                        <li className={''}>
                            <a href="/portfolio" className={'nav-link text-secondary text-decoration-none'}>Work</a>
                        </li>
                        <li className={''}>
                            <a href="/#products" className={'nav-link text-secondary text-decoration-none'}>Products</a>
                        </li>
                        <li className={''}>
                            <a href="https://yaroslav.lukyanchuk.com/about" target={'_blank'} className={'nav-link text-secondary text-decoration-none'}>About</a>
                        </li>
                        <li className={''}>
                            <a href="https://yaroslav.lukyanchuk.com" target={'_blank'} className={'nav-link text-secondary text-decoration-none'}>Contacts</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <LanguageSwitch color={'white'} />
        </div>

    </header>);
}

export default HeaderComponent;
